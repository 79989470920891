<template>
  <yb-drop-down-item
    class="w-full"
    icon="instance"
    :selected="selected"
    link-class="py-1"
    wrapper-class="yb-view"
    :data-test-id="dataTestId + '-' + option.name"
    @click="click"
  >
    <div v-if="!truncate || option.name.length <= truncate" class="yb-view-content truncate text-ellipsis">
      {{ option.name }}
    </div>
    <div v-else v-tooltip="option.name" class="yb-view-content truncate text-ellipsis">
      {{ formatOptionName(option.name) }}
    </div>
    <yb-icon v-tooltip="'Instance State: ' + option.activeStatus.description" :icon="getOpIcon(option.activeStatus.description)" class="w-4 h-4 mr-1 ml-4 mt-0.5 inline-block yb-view-right" :class="getStatusColorClass(option.activeStatus.description)" />
  </yb-drop-down-item>
</template>

<script>

export default {
  name: 'YbInstancesSelectorItem',
  props: {
    option: {
      type: Object
    },
    truncate: {
      type: Number
    },
    selected: {
      type: Boolean
    },
    dataTestId: String
  },
  emits: [
    'click'
  ],
  data() {
    return {
    }
  },
  methods: {
    click($event) {
      this.$emit('click', $event)
    },
    formatOptionName(name) {
      if (!this.truncate || name.length <= this.truncate) {
        return name
      }
      return name.substring(0, this.truncate) + '...'
    },
    getOpIcon,
    getStatusColorClass
  }
}
</script>
